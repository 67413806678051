import './App.css';
import AppBody from './components/AppBody';
import AppFooter from './components/AppFooter';
import AppHeader from './components/AppHeader';
import { Box, createTheme, ThemeProvider } from '@mui/material';

const THEME = createTheme({
  typography: {
   "fontFamily": `Comic Sans MS, Comic Sans, Chalkboard, ChalkboardSE-Regular, sans-serif`,
   "fontSize": 14,
   "fontWeightLight": 300,
   "fontWeightRegular": 400,
   "fontWeightMedium": 500,
   body2: {
    lineHeight: 2
   }
  },
  spacing: (2)
});

function App() {
  return (
    <ThemeProvider theme={THEME}>
      <div className="App">
        <Box sx={{ display: "block", height: "70px"}}></Box>
        <AppHeader />
        <AppBody />
        <AppFooter />
      </div>
    </ThemeProvider>
  );
}

export default App;
