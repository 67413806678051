import { Box, ButtonBase, Card, CardActions, CardContent, CardMedia, Container, Grid, IconButton, Link, Typography } from "@mui/material";
import { styled } from "@mui/material"; 
// portrait
import portrait_momonta from "../assets/portrait/portrait_momenta.jpg";
// logo
import bilibili_logo from "../assets/logo/bilibili.svg";
import blog_logo from "../assets/logo/blog.svg";
import email_logo from "../assets/logo/email.svg";
import github_logo from "../assets/logo/github.svg";
import twitter_logo from "../assets/logo/twitter.svg";
// images
import lemon_cake from "../assets/food/lemon_cake.jpg";
import xinhuaxi_fqhn from "../assets/food/xinhuaxi_fqhn.jpg"
import bbq from "../assets/food/BBQ.jpeg";
import foie_gras from "../assets/food/foie_gras.jpeg";
import kebab from "../assets/food/kebab.jpeg";
import passion_fruit from "../assets/food/passion_fruit.jpeg"
import pesto_pasta from "../assets/food/pesto_pasta.jpeg";
import pizza from "../assets/food/pizza.jpeg";
import wine from "../assets/food/wine.jpeg";

const ImageBackdrop = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  background: '#000',
  opacity: 0.5,
  transition: theme.transitions.create('opacity'),
}));

const ImageIconButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  display: 'block',
  padding: 0,
  borderRadius: 0,
  height: '40vh',
  [theme.breakpoints.down('md')]: {
      width: '100% !important',
      height: 400 
  },
  '&:hover': {
      zIndex: 1,
  },
  '&:hover .imageBackdrop': {
      opacity: 0.15,
  },
  '&:hover .imageMarked': {
      opacity: 0,
  },
  '&:hover .imageTitle': {
      border: '4px solid currentColor',
  },
  '& .imageTitle': {
      position: 'relative',
      padding: `${theme.spacing(2)} ${theme.spacing(4)} 14px`,
  },
  '& .imageMarked': {
      height: 3,
      width: 18,
      background: theme.palette.common.white,
      position: 'absolute',
      bottom: -2,
      left: 'calc(50% - 9px)',
      transition: theme.transitions.create('opacity'),
  },
}));

const images = [
  {
    url: lemon_cake,
    title: 'Lemon Cake',
    width: '30%',
  },
  {
    url: xinhuaxi_fqhn,
    title: 'Beef Rice Noodle',
    width: '40%',
  },
  {
    url: bbq,
    title: 'BBQ',
    width: '30%',
  },
  {
    url: foie_gras,
    title: 'Foie Gras',
    width: '40%',
  },
  {
    url: kebab,
    title: 'Kebab',
    width: '30%',
  },
  {
    url: passion_fruit,
    title: 'Passion Fruit',
    width: '30%',
  },
  {
    url: pesto_pasta,
    title: 'Pesto Pasta',
    width: '30%',
  },
  {
    url: pizza,
    title: 'Pizza',
    width: '40%',
  },
  {
    url: wine,
    title: 'Wine',
    width: '30%',
  },
]

export default function AppBody() {
  return (
    <>
      <Container maxWidth={false} sx={{mt: 0.5}}>
        <Grid container>
          <Grid item xs={0} md={2}></Grid>
          <Grid item xs={12} md={3.1}>
            <Card variant="outlined" sx={{mb:0.5}}>
              <CardMedia
                component="img"
                image={portrait_momonta}
                alt="portrait"
                sx={{}}
              />
              <CardContent sx={{ pb: 0}}>
                <Typography variant="h2" component="div" sx={{color: "#3874cb", margin: 0}} align="center">
                  JIN YUBIN
                </Typography>
                <Typography variant="body2" color="text.secondary" align="center">
                  <Link href="mailto:imdanteking@gmail.com" underline="none" sx={{ margin: 0}}>
                    email: imdanteking@gmail.com
                  </Link>
                </Typography>
              </CardContent>
              <CardActions sx={{ display: "flex", justifyContent: "center"}}>
                <IconButton href="https://github.com/DanteIoVeYou">
                  <img src={github_logo} alt="github_logo" style={{width: "30px"}}></img>
                </IconButton>
                <IconButton href="https://space.bilibili.com/349104969">
                  <img src={bilibili_logo} alt="bilibili_logo" style={{width: "30px"}}></img>
                </IconButton>
                <IconButton href="https://twitter.com/imdanteking">
                  <img src={twitter_logo} alt="twitter_logo" style={{width: "30px"}}></img>
                </IconButton>
                <IconButton href="http://blog.imdanteking.com/">
                  <img src={blog_logo} alt="blog_logo" style={{width: "30px"}}></img>
                </IconButton>
                <IconButton href="mailto:imdanteking@gmail.com">
                  <img src={email_logo} alt="email_logo" style={{width: "30px"}}></img>
                </IconButton>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={0} md={0.5}></Grid>
          <Grid item xs={12} md={5}>
              <Card variant="outlined">
                <CardContent  sx={{lineHeight: 1}}>
                  {/* Education */}
                  <Typography variant="h6">Education</Typography>
                  <Typography variant="body2">BSc, Chemistry, 2020.09 ~ now</Typography>
                  <Typography variant="body2" style={{textIndent: "2em"}}>College of Chemistry, Soochow University, China</Typography>
                  <Typography variant="body2">Minor, Computer Science and Technology, 2022.09 ~ 2023.05</Typography>
                  <Typography variant="body2" style={{textIndent: "2em"}}>School of Computer Science & Technology, Soochow University, China</Typography>
                  <Typography variant="body2">Exchange student, Chemistry, 2023.08 ~ now</Typography>
                  <Typography variant="body2" style={{textIndent: "2em"}}>School of Chemistry, Chemical Engineering and Biotechnology, Nanyang Technological University, Singapore</Typography>
                  <br/>
                  {/* Experience */}
                  <Typography variant="h6">Experience</Typography>
                  <Typography variant="body2">Operations Director, 2022.06 ~ 2023.06</Typography>
                  <Typography variant="body2" style={{textIndent: "2em"}}>Soochow University Microsoft Student Club, Suzhou, China</Typography>
                  <Typography variant="body2">Software Development Intern, 2022.07 ~ 2022.08</Typography>
                  <Typography variant="body2" style={{textIndent: "2em"}}>Hundsun Technologies Inc., Hangzhou, China</Typography>
                  <Typography variant="body2">Research & Development Intern, 2022.12 ~ 2023.07</Typography>
                  <Typography variant="body2" style={{textIndent: "2em"}}>Momenta, Suzhou, China</Typography>
                  <br/>
                  {/* Skills */}
                  <Typography variant="h6">Skills</Typography>
                  <Typography variant="body2">System Development</Typography>
                  <Typography variant="body2" style={{textIndent: "2em"}}>C/C++, Shell</Typography>
                  <Typography variant="body2">Web Development</Typography>
                  <Typography variant="body2" style={{textIndent: "2em"}}>Java, JavaScript, SpringBoot, React, Antd, BootStrap</Typography>
                  <Typography variant="body2">Data Analysis</Typography>
                  <Typography variant="body2" style={{textIndent: "2em"}}>Python, numpy, pandas, matplotlib, Keras, scikit-learn</Typography>
                </CardContent>
              </Card>
          </Grid>
        </Grid> 
        <Container sx={{ mt:8 }}>
          <Typography variant="h4" marked="center" align="center" component="h2">
            yubin's recipe
          </Typography>
          <Box sx={{ mt: 8,  display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
              {images.map((image) => (
                <ImageIconButton
                  key={image.title}
                  style={{
                    width: image.width,
                  }}
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      left: 0,
                      right: 0,
                      top: 0,
                      bottom: 0,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      backgroundImage: `url(${image.url})`,
                    }}
                  />
                  <ImageBackdrop className="imageBackdrop" />
                  <Box
                    sx={{
                      position: 'absolute',
                      left: 0,
                      right: 0,
                      top: 0,
                      bottom: 0,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: 'common.white',
                    }}
                  >
                    <Typography
                      component="h3"
                      variant="h6"
                      color="inherit"
                      className="imageTitle"
                    >
                      {image.title}
                      <div className="imageMarked" />
                    </Typography>
                  </Box>
                </ImageIconButton>
              ))}
          </Box>
        </Container>
      </Container>
    </>
  );
}